/* -------------- container --------------*/
.frame{
	max-width: 1680px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
}

.video-frame {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	iframe, object, embed{
		@include size(100%);
		@include abs_pos($t:0, $l:0);
	}
}