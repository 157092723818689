/* -------------- temp RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .page-magazine .exam-grid{
        padding: 40px 30px;
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-magazine{
        .exam-file{
            gap: 10px;
            flex-wrap: wrap;
            .link-remark{
                margin: 0;
                width: 100%;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-magazine{
        .magazine-head{
            margin-top: -29px;
            .info{
                font-size: 1.0625rem /* 17/16 */;
                padding: 10px;
                padding-left: 15px;
                height: 44px;
                &:before{
                    border-width: 44px 15px 0 0;
                }
            }
            .exam-link{
                font-size: 1.0625rem /* 17/16 */;
                padding: 10px;
                padding-bottom: 11px;
                padding-right: 15px;
                margin-left: 17px;
                height: 44px;
                &:before{
                    border-width: 0 0 44px 15px;
                }
            }
        }
        .exam-top-head{
            .main{
                font-size: 1rem /* 16/16 */;
            }
            .sub{
                font-size: .9375rem /* 15/16 */;
            }
        }
        .exam-grid{
            padding: 30px 15px;
            .exam-head{
                margin-bottom: 20px;
            }
            .exam{
                .exam-q{
                    margin-bottom: 8px;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }