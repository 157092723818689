/* -------------- download RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-download{
        .download{
            grid-template-columns: 100px auto;
            gap: 5px;
            padding: 13px 15px;
            .file-name{
                grid-column: 1 / -1;
            }
        }
    }
}

// @media (max-width: $w_mo){ }