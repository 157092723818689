/* -------------- about RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-about{
        .content-head{
            display: block;
            margin-bottom: 20px;
        }
        .page-title{
            margin-bottom: 15px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-about .committee-group {
        margin-top: 30px;
    }
}

// @media (max-width: $w_mo){ }