$c_font: #281d19;
$c_fontL: #555;
$c_white: #fff;
$c_main: #ff9728;
$c_light: #fde7c6;
$c_dark: #ffcc33;
$c_active: #e93c00;
$c_grey: #e2e2e2;
$c_link: #2365af;
$ff: 'Raleway', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;

$w_pc_b: calc(1440px + 40px);
$w_pc: 1280px;
$w_pc_m: 1024px;
$w_tb_b: 960px;
$w_tb: 768px;
$w_tb_m: 640px;
$w_mo: 480px;
$w_i6p: 414px;
$w_i6: 375px;
$w_mo_m: 320px;

$fz: 10px;

$fa-font-path: "../webfonts";