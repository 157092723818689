/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	margin-left: auto;
	margin-right: auto;
}

body{
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}
a{
	text-decoration: none;
	transition: all .4s ease;
}
button{
	transition: all .4s ease;
}

.unreset{
	line-height: initial;
	a{
		text-decoration: underline;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
	p{
		color: $c_font;
		font-size: 1rem /* 16/16 */;
		line-height: 1.5;
	}
}

.sec-head{
	@include flex($ai:flex-end);
	padding-left: 40px;
	position: relative;
	gap: 10px;
	&:before{
		@include abs_pos($t:-15px, $l:0);
		@include size(60px, 40px);
		background: url(../images/dec-head.svg) no-repeat;
		content: '';
	}
	> *{
		position: relative;
	}
	p{
		margin: 0;
	}
	.title{
		color: $c_font;
		font-size: 2.5rem /* 40/16 */;
		font-weight: bold;
		line-height: 1;
	}
	.sub{
		color: $c_main;
		font-size: 1.875rem /* 30/16 */;
		font-family: $ff;
		text-transform: uppercase;
		line-height: 1;
	}
}

.more-link{
	align-items: center;
	display: inline-flex;
	gap: 20px;
	&:hover{
		.icon{
			background: $c_main;
		}
	}
	span{
		color: $c_font;
		font-size: 1rem /* 16/16 */;
		font-family: $ff;
		text-transform: uppercase;
	}
	.icon{
		@include size(30px);
		@include flex($ai:center, $jfc:center);
		background: $c_active;
		i{
			color: $c_white;
			font-size: .875rem /* 14/16 */;
		}
	}
}

.social-link{
	border: 1px solid $c_grey;
    box-shadow: 0 0 0px $c_light;
	background: $c_white;
	padding: 10px;
	margin: 7px 10px;
	transition: all .4s ease;
	&:hover{
		box-shadow: -7px 7px 0px $c_light;
	}
}

.img-limit{
	display: block;
	position: relative;
	&.out-link{
		@include fiximg(100%, calc(120 / 300 * 100%));
	}
}

.page-banner{
	box-shadow: 0px 0px 10px 0px rgba(black, 0.15);
	img{
		display: block;
		width: 100%;
	}
}

.breadcrumb{
	@include flex($jfc:flex-end);
	padding-top: 70px;
	padding-bottom: 20px;
	margin: 0;
	list-style: none;
	li{
		&:not(:last-child){
			&:after{
				content: '/';
				font-size: .9375rem /* 15/16 */;
				color: #aaa;
				margin: 0 5px;
			}
		}
	}
	a{
		color: $c_fontL;
		font-size: .9375rem /* 15/16 */;
		line-height: 1.2;
		&:hover{
			color: $c_main;
		}
	}
}

.page-frame{
	@include flex($ai:flex-start);
	padding-bottom: 80px;
	&.wide{
		display: block;
		.page-frame-main{
			padding-left: 0;
			margin-top: 0;
			width: 100%;
		}
	}
}

aside{
	width: 300px;
	.aside-head{
		@include flex($ai:flex-end);
		padding-left: 20px;
		position: relative;
		gap: 10px;
		margin-bottom: 20px;
		&:before{
			@include abs_pos($t:-10px, $l:0);
			@include size(40px, 30px);
			background: url(../images/dec-head.svg) no-repeat;
			content: '';
		}
		> *{
			position: relative;
		}
		p{
			margin: 0;
		}
		.title{
			color: $c_font;
			font-size: 1.875rem /* 30/16 */;
			font-weight: bold;
			line-height: 1;
		}
		.sub{
			color: $c_main;
			font-size: 1.0625rem /* 17/16 */;
			font-family: $ff;
			text-transform: uppercase;
			line-height: 1;
		}
	}
	.toggle-page-menu{
		border: 1px solid #eee;
		background: #f9f9f9;
		color: $c_font;
		padding: 13px 20px;
		font-size: 1rem /* 16/16 */;
		font-weight: bold;
		line-height: 1;
		width: 100%;
		display: none;
		&:hover{
			border-color: $c_main;
			color: $c_main;
		}
		i{
			margin-right: 7px;
		}
	}
	.aside-menu{
		list-style: none;
		padding: 0;
		margin: 0;
		margin-bottom: 20px;
		>li{
			border-bottom: 1px solid #ddd;
			overflow: hidden;
			&.active{
				> a{
					background: $c_main;
					color: $c_white !important;
				}
			}
			> a{
				border-radius: 5px;
				color: $c_font;
				display: block;
				font-size: 1.1875rem /* 19/16 */;
				line-height: 1.3;
				padding: 15px 20px;
				&:hover{
					color: $c_active;
				}
			}
		}
	}
	.sub-menu{
		list-style: none;
		padding-left: 20px;
		margin: 10px 0;
		li{
			margin: 5px 0;
			padding-left: 15px;
			position: relative;
			&:before{
				@include abs_pos($t:3px, $l:0);
				content: '\f105';
				color: $c_font;
				font-size: .9375rem /* 15/16 */;
				font-family: 'Font Awesome 6 Free';
				font-weight: bold;
			}
			&.active a{
				color: $c_main;
			}
		}
		a{
			color: $c_font;
			font-size: 1rem /* 16/16 */;
			line-height: 1.2;
			&:hover{
				color: $c_active;
			}
		}
	}
	.aside-dec{
		&.is-cover{
			display: block;
			margin-top: 20px;
		}
		img{
			display: block;
			width: 100%;
		}
	}
}

.page-frame-main{
	padding-left: 80px;
	width: calc(100% - 300px);
	.main-head{
		border-bottom: 1px solid $c_grey;
		padding-left: 15px;
		padding-bottom: 15px;
		position: relative;
		margin-bottom: 40px;
		&:before{
			@include abs_pos($t:5%, $l:0);
			@include size(5px, 25px);
			background: $c_main;
			border-radius: 3px;
			content: '';
		}
		.title{
			color: $c_font;
			font-size: 1.6875rem /* 27/16 */;
			line-height: 1.2;
			margin: 0;
		}
	}
}

.share-sns{
	@include flex($ai:center);
	margin-top: 30px;
	p{
		margin: 0;
	}
	.title{
		color: $c_fontL;
		font-size: 1.0625rem /* 17/16 */;
		i{
			padding-right: 3px;
		}
	}
	.a2a_kit{
		@include flex($ai:center);
		gap: 10px;
		i{
			color: $c_fontL;
			font-size: 1.125rem /* 18/16 */;
			line-height: 1;
		}
		.fa-facebook-f:hover{
			color: #1877F2;
		}
		.fa-line:hover{
			color: #00b900;
		}
		.fa-envelope:hover{
			color: $c_active;
		}
	}
}

.term.grid{
	td{
		color: $c_font;
		line-height: 1.5;
		vertical-align: baseline;
	}
}

.table-grid{
	table{
		border-collapse: collapse;
		width: 100%;
	}
	thead{
		td{
			background: #ffe9d2;
			text-align: center;
		}
	}
	td{
		border: 1px solid #ddd;
		background: $c_white;
		color: $c_font;
		font-size: 1rem /* 16/16 */;
		line-height: 1.4;
		padding: 18px;
	}
}

.page-info-list{
	@include flex;
	&.in-list{
		border-top: 1px solid $c_grey;
		padding-top: 13px;
		margin-top: 13px;
	}
	.info{
		color: $c_fontL;
		display: flex;
		font-size: .875rem /* 14/16 */;
		line-height: 1.2;
		position: relative;
		margin: 3px 0 !important;
		&:not(:last-child):after{
			content: '/';
			padding: 0 10px;
		}
		.title{
			flex-shrink: 0;
		}
	}
	.date-info{
		padding-left: 20px;
		position: relative;
		&:before{
			@include abs_pos($t:0, $l:0);
			content: '\f017';
			color: #999;
			font-family: 'Font Awesome 6 Free';
			font-weight: bold;
			font-size: .8125rem /* 13/16 */;
		}
	}
	.catregory-info{
		padding-left: 20px;
		position: relative;
		&:before{
			@include abs_pos($t:0, $l:0);
			content: '\f07c';
			color: #999;
			font-family: 'Font Awesome 6 Free';
			font-weight: bold;
			font-size: .8125rem /* 13/16 */;
		}
	}
	.people-info{
		padding-left: 20px;
		position: relative;
		&:before{
			@include abs_pos($t:0, $l:0);
			content: '\f2bd';
			color: #999;
			font-family: 'Font Awesome 6 Free';
			font-weight: bold;
			font-size: .8125rem /* 13/16 */;
		}
	}
	.download-link{
		position: relative;
		padding-left: 20px;
		&:hover span{
			color: $c_active;
		}
		&:before{
			@include abs_pos($t:0, $l:0);
			content: '\f0ed';
			color: $c_main;
			font-family: 'Font Awesome 6 Free';
			font-weight: bold;
			font-size: .8125rem /* 13/16 */;
		}
		span{
			color: $c_link;
			font-size: .875rem /* 14/16 */;
			display: inline-block;
			text-decoration: underline;
		}
	}
}

.page-detail-head{
	align-items: flex-start;
	display: grid;
	grid-template-columns: auto 130px;
	gap: 20px;
	margin-bottom: 40px;
	&.full-wide{
		display: block;
	}
	.detail-title{
		color: $c_font;
		font-size: 1.375rem /* 22/16 */;
		font-weight: bold;
		line-height: 1.2;
		margin-top: 0;
		margin-bottom: 15px;
	}
}