/* -------------- form --------------*/
button, input[type="reset"], input[type="submit"]{
	cursor: pointer;
}

input[type="reset"], input[type="submit"], input[type="text"], input[type="email"], input[type="tel"], textarea, select, input[type="date"], input[type="time"], input[type="number"], input[type="password"], input[type="url"], button{
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
	box-shadow: none;
	border: none;
	outline: none;
}

select::-ms-expand{
	display: none;
}

.select-style{
    border: 1px solid $c_grey;
	background: $c_white;
    position: relative;
    &:before{
        @include abs_pos($t: 50%, $r: 10px);
        content: '\f0dd';
        display: block;
        font-family: 'Font Awesome 6 Free';
        font-weight: bold;
        color: $c_font;
        transform: translateY(-80%);
    }
    select{
        background: transparent;
        color: $c_font;
        font-size: 1rem /* 16/16 */;
        line-height: 1.2;
        padding: 12px;
        padding-right: 30px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
}

.login-form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
    .wide{
        grid-column: 1 / -1;
    }
    .field-label{
        p{
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1.3;
            margin: 0;
            margin-bottom: 10px;
        }
    }
    input[type="text"], input[type="password"]{
        background: #eee;
        border-radius: 3px;
        color: $c_font;
        font-size: 1rem /* 16/16 */;
        line-height: 1;
        padding: 15px 20px;
        width: 100%;
    }
    .submit{
        background: $c_active;
        border-radius: 3px;
        color: $c_white;
        font-size: 1.0625rem /* 17/16 */;
        font-weight: bold;
        text-align: center;
        padding: 15px;
        width: 100%;
        &:hover{
            background: $c_main;
        }
    }
}

.form-sec-head{
    border-radius: 3px;
    background: #f4f4f4;
    padding: 15px;
    padding-left: 40px;
    position: relative;
    &:before{
        @include abs_pos($t:16px, $l:15px);
        content: '\f35a';
        color: $c_active;
        font-size: 1rem /* 16/16 */;
        font-family: 'Font Awesome 6 Free';
        font-weight: bold;
        line-height: 1;
    }
    p{
        color: $c_font;
        font-size: 1.0625rem /* 17/16 */;
        font-weight: bold;
        line-height: 1.2;
        margin: 0;
    }
}

.must-des{
    color: $c_font;
    font-size: 1rem /* 16/16 */;
    line-height: 1.2;
    span{
        color: $c_active;
        font-size: 1rem /* 16/16 */;
        font-weight: bold;
    }
}

.form-style{
    display: grid;
    grid-template-columns: 55% auto;
    gap: 20px 30px;
    &.third-form{
        grid-template-columns: 35% 30% auto;
        padding: 30px 0;
        .three-halves{
            grid-column: span 2;
        }
        .file-input {
            width: 100%;
            .file-cell{
                width: 100%;
            }
            .file-name{
                border-color: #ccc;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                padding: 13px 15px;
                min-width: calc(100% - 95px);
                width: 100%;
            }
            .file-btn{
                background: #666;
                padding: 14px 15px;
            }
        }
        .btn-row{
            button{
                background: $c_active;
                min-width: 330px;
                &:hover{
                    background: $c_main;
                }
            }
        }
    }
    &.inline-form{
        padding: 0;
        .field{
            display: flex;
            .field-label{
                flex-shrink: 0;
                padding-top: 12px;
                min-width: 110px;
            }
            .field-content{
                width: 100%;
            }
        }
        .btn-row{
            button{
                background: $c_main;
                min-width: 230px;
                &:hover{
                    background: $c_active;
                }
            }
        }
    }

    .wide{
        grid-column: 1 / -1;
    }
    .field-label{
        margin-bottom: 7px;
        &.miltiple-items{
            @include flex($ai:baseline);
            gap: 10px;
        }
        p{
            color: $c_font;
            display: inline-block;
            font-size: 1rem /* 16/16 */;
            line-height: 1.3;
            margin: 0;
            position: relative;
            &.must:before{
                @include abs_pos($t:-2px, $r:-10px);
                content: '*';
                color: $c_active;
                font-size: 1rem /* 16/16 */;
                font-weight: bold;
            }
        }
    }
    .field-content{
        @include placeholder(#aaa);
        align-items: center;
        display: flex;
        gap: 15px;
        &.wide-remark{
            gap: 0;
            flex-wrap: wrap;
        }
        input[type="text"], input[type="tel"], input[type="email"], input[type="password"], textarea{
            border: 1px solid #ccc;
            border-radius: 3px;
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            padding: 12px 15px;
            width: 100%;
        }
        textarea{
            resize: none;
            height: 120px;
        }
        .select-style{
            border-color: #ccc;
            border-radius: 3px;
            width: 100%;
        }
    }
    .cheked-list{
        @include flex;
        gap: 10px;
        flex-shrink: 0;
        span{
            color: $c_font;
        }
    }
    .remark{
        color: #999;
        font-size: 1rem /* 16/16 */;
        line-height: 1.3;
        margin: 0;
        margin-top: 7px;
    }
    .file-input{
        display: flex;
        gap: 20px;
        .file-cell{
            align-items: baseline;
            cursor: pointer;
            display: flex;
            flex-shrink: 0;
        }
        input[type="file"]{
            display: none;
        }
        .file-name{
            border: 1px solid $c_grey;
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            padding: 12px 15px;
            margin: 0;
            min-width: 270px;
        }
        .file-btn{
            background: $c_active;
            color: $c_white;
            display: inline-block;
            font-size: 1rem /* 16/16 */;
            flex-shrink: 0;
            line-height: 1;
            padding: 12px 15px;
        }
    }
    .pw-field{
        position: relative;
        width: 100%;
        &.open .eye{
            &:before{
                display: block;
            }
            &:after{
                display: none;
            }
        }
        .eye{
            @include abs_pos($t:50%, $r:10px);
            transform: translateY(-50%);
            &:before, &:after{
                color: #666;
                cursor: pointer;
                font-family: 'Font Awesome 6 Free';
                font-size: 1rem /* 16/16 */;
            }
            &:before{
                content: '\f06e';
                display: none;
            }
            &:after{
                content: '\f070';
            }
        }
        input[type="password"]{
            padding-right: 35px;
        }
    }
    .btn-row{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        button{
            background: $c_main;
            border-radius: 3px;
            color: $c_white;
            font-size: 1.0625rem /* 17/16 */;
            padding: 15px;
            text-align: center;
            min-width: 130px;
            &:hover{
                background: $c_active;
            }
        }
    }
}
