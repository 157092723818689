/* -------------- header --------------*/
.header{
    border-top: 10px solid $c_main;
    background: $c_white;
    padding-top: 30px;
    padding-bottom: 35px;
    ul{
        padding-left: 0;
        margin: 0;
        li{
            list-style: none;
        }
    }
    .frame{
        @include flex($ai:flex-end, $jfc:space-between);
    }
    .logo{
        @include maw(450px);
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
    .others-link{
        @include flex($jfc:flex-end);
        gap: 40px;
        a{
            color: $c_font;
            display: block;
            font-size: 1.125rem /* 18/16 */;
            position: relative;
            line-height: 1;
            i{
                color: $c_main;
                margin-right: 10px;
            }
            span{
                position: relative;
                &:before{
                    @include abs_pos($b:0, $l:0);
                    @include size(0, 8px);
                    background: rgba($c_dark, .5);
                    content: '';
                }
            }
            &:not(:last-child):after{
                @include abs_pos($t:4px, $r:-20px);
                @include size(1px, 10px);
                background: #ccc;
                content: '';
            }
            &:hover span:before{
                transition: all .4s ease;
                width: 100%;
            }
        }
    }
    .main-menu{
        @include flex;
        gap: 45px;
        margin-top: 30px;
        z-index: 10;
        > li{
            position: relative;
            &.has-sub .main-link{
                margin-right: 23px;
                position: relative;
                &:after{
                    @include abs_pos($t:4px, $r:-23px);
                    content: '\f0d7';
                    color: $c_active;
                    font-family: 'Font Awesome 6 Free';
                    font-weight: bold;
                    font-size: 1.125rem /* 18/16 */;
                }
            }
        }
        .main-link{
            color: $c_font;
            font-size: 1.375rem /* 22/16 */;
            font-weight: bold;
            position: relative;
            line-height: 1;
            &:before{
                @include abs_pos($b:0, $l:0);
                @include size(0, 8px);
                background: rgba($c_dark, .5);
                content: '';
            }
            &:hover:before{
                transition: all .4s ease;
                width: 100%;
            }
        }
    }
    .sub-menu{
        @include abs_pos($b:0, $r:0);
        display: none;
        padding-top: 20px;
        transform: translateY(100%);
        width: 200px;
        z-index: 1;
        li{
            margin-bottom: 1px;
        }
        a{
            background: $c_main;
            color: $c_white;
            display: block;
            font-size: 1.125rem /* 18/16 */;
            line-height: 1.4;
            padding: 15px 20px;
            &:hover{
                background: $c_active;
            }
        }
    }
}


.mobile-toggle {
    @include abs_pos($t:35px, $r:40px);
    @include size(28px, 30px);
    background: none;
    display: none;
    transform: translateY(13px);
    padding: 0;
    z-index: 4;

    &.open {
        span,
        span:before,
        span:after {
            background: $c_active;
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 3px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        transition: all .4s ease;
    }
}