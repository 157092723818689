/* -------------- sitemap --------------*/
.sitemap-row{
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    a{
        text-decoration: inherit;
    }
    .map-item{
        border-right: 1px solid #fff;
        box-sizing: border-box;
        margin-bottom: 30px;
        width: calc(100% / 5);
        .title-link{
            background: $c_main;
            color: #fff;
            display: block;
            font-size: 18px;
            line-height: 1.2;
            padding: 15px 25px 12px;
            text-align: center;
            &:hover{
                font-weight: bold;
            }
        }
    }
    .map-list{
        border-left: 1px solid #ccc;
        margin-top: 15px;
        margin-bottom: 0;
        padding-left: 25px;
        padding-right: 20px;
        
        li{
            list-style: none;
        }
        >li{
            padding-top: 8px;
            padding-bottom: 8px;
        }
        .second-link{
            color: #333;
            font-size: 17px;
            line-height: 1.3;
            &:hover{
                font-weight: bold;
                position: relative;
                &:before{
                    background: #fff;
                    content: '\f105';
                    color: #333;
                    font-family: 'Font Awesome 6 Free';
                    font-weight: bold;
                    font-size: 16px;
                    position: absolute;
                    top: -1px;
                    left: -30px;
                }
            }
        }
    }
    .third-list{
        padding-left: 25px;
        margin-top: 10px;
        >li{
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .third-link{
            color: #333;
            font-size: 16px;
            line-height: 1.3;
            position: relative;
            &:before{
                color: #aaa;
                content: "\f0da";
                font-family: 'Font Awesome 6 Free';
                font-weight: bold;
                font-size: 14px;
                position: absolute;
                top: 1px;
                left: -15px;
            }
            &:hover{
                font-weight: bold;
                &:before{
                    color: #333;
                }
            }
        }
    }
}

@media (max-width:1024px){
    .sitemap-row{
        .map-item{
            width: calc(100% / 4);
        }
    }
}

@media (max-width:960px){
    .sitemap-row{
        .map-item{
            width: calc(100% / 3);
        }
    }
}

@media (max-width:768px){
    .sitemap-row{
        .map-item{
            width: calc(100% / 2);
            .title-link{
                padding: 15px;
                padding-bottom: 12px;
            }
        }
        .map-list{
            padding-left: 15px;
            padding-right: 10px;
            > li{
                padding-top: 6px;
                padding-bottom: 6px;
            }
            .second-link{
                font-size: 1rem /* 16/16 */;
                &:hover:before{
                    left: -20px;
                }
            }
        }
        .third-list{
            margin-top: 5px;
        }
    }
}