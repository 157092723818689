/* -------------- about --------------*/
.page-about{
    .content-head{
        @include flex($ai:flex-end, $jfc:space-between);
        margin-bottom: 30px;
    }
    .page-title{
        color: $c_font;
        font-size: 1.0625rem /* 17/16 */;
        line-height: 1.4;
        margin: 0;
    }
    .committee-head{
        .master{
            @include flex($ai:baseline);
            p{
                margin: 0;
            }
            .name{
                color: $c_font;
                font-size: 1.5rem /* 24/16 */;
                font-weight: bold;
                margin-right: 5px;
            }
            .period{
                color: $c_fontL;
                display: inline-block;
                font-size: .9375rem /* 15/16 */;
            }
        }
    }
    .committee-group{
        margin-top: 50px;
    }
    .group-head{
        margin-bottom: 20px;
        padding-left: 25px;
        position: relative;
        &:before{
            @include abs_pos($t:1px, $l:0);
            content: '\f0c9';
            color: $c_active;
            font-size: 1.125rem /* 18/16 */;
            font-family: 'Font Awesome 6 Free';
            font-weight: bold;
        }
        .sub{
            color: $c_font;
            font-size: 1.125rem /* 18/16 */;
            font-weight: bold;
            line-height: 1.2;
            margin: 0;
        }
    }
}