/* -------------- member --------------*/
.page-login{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 45px;
    max-width: 760px;
    p{
        margin: 0;
    }
    .login-form{
        gap: 10px;
        grid-template-columns: 1fr;
    }
    .form-head{
        color: $c_font;
        font-size: 1.375rem /* 22/16 */;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .form-des{
        border-left: 3px solid $c_main;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-bottom: 50px;
        p{
            color: $c_font;
            font-size: .9375rem /* 15/16 */;
            line-height: 1.2;
            &:not(:last-child){
                margin-bottom: 8px;
            }
        }
    }
}