/* -------------- index RWD --------------*/
@media (max-width: 1600px){ 
    .index-magazine{
        &:after{
            width: calc(100% - 1120px - 100px);
        }
        .magazine-grid{
            padding: 50px;
            margin-top: 70px;
        }
        .list-head{
            left: 50px;
        }
    }
}

@media (max-width: $w_pc_b){ 
    .index-banner{
        .slick-arrow{
            width: 20px;
            &.slick-prev{
                left: 70px;
            }
            &.slick-next{
                right: 70px;
            }
        }
    }
    .index-intro {
        .frame{
            gap: 40px;
        }
        .about-sec .des{
            font-size: 1.125rem /* 18/16 */;
        }
    }
    .index-news{
        .news-item{
            padding: 30px;
            .news-title{
                margin-top: 20px;
            }
        }
    }
    .index-activities{
        .activity-grid{
            padding: 80px 8%;
        }
    }
    .index-magazine{
        &:after{
            width: calc(100% - 70% - 40px);
        }
        .magazine-grid{
            max-width: 70%;
        }
        .chapter{
            padding: 18px 0;
        }
    }
}

@media (max-width: $w_pc){ 
    .index-banner{
        .slick-arrow{
            &.slick-prev{
                left: 40px;
            }
            &.slick-next{
                right: 40px;
            }
        }
        .slick-dots{
            bottom: 20px;
        }
    }
    .index-news{
        .news-grid{
            padding-top: 40px;
        }
        .news-item{
            padding: 25px;
            .news-title{
                margin-top: 15px;
            }
        }
    }
    .index-activities{
        .head{
            .title{
                font-size: 1.875rem /* 30/16 */;
            }
            .sub{
                font-size: 1.625rem /* 26/16 */;
            }
        }
        .activity-item{
            grid-template-columns: 70px auto;
            gap: 20px;
        }
    }
    .index-magazine{
        padding-top: 60px;
    }
}

@media (max-width: 1200px){ 
    .index-intro{
        padding-top: 80px;
        padding-bottom: 80px;
        .sec-head{
            margin-bottom: 25px;
        }
        .sec-content{
            padding-left: 20px;
        }
        .about-sec{
            .des{
                margin-bottom: 60px;
            }
        }
        .login-sec{
            background: none;
            padding-bottom: 0;
        }
    }
    .index-magazine{
        &:after{
            display: none;
        }
        .magazine-grid{
            max-width: 100%;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .index-news{
        background: none;
        padding-bottom: 60px;
        .news-grid{
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .index-activities{
        grid-template-columns: 1fr;
        .activity-grid{
            transform: translateY(0);
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .index-banner{
        .slick-arrow{
            width: 15px;
            &.slick-prev{
                left: 20px;
            }
            &.slick-next{
                right: 20px;
            }
        }
        .slick-dots{
            bottom: 10px;
        }
    }
    .index-intro{
        padding-top: 50px;
        padding-bottom: 50px;
        .frame{
            display: grid;
            grid-template-columns: 1fr;
            gap: 50px;
        }
        .about-sec{
            .des{
                font-size: 1.0625rem /* 17/16 */;
                margin-bottom: 20px;
            }
        }
    }
    .index-news{
        .news-grid{
            padding-top: 20px;
        }
        .news-item{
            &:hover{
                background-size: 200px;
            }
            .news-title{
                height: calc(1rem * 1.6 * 4);
                a{
                    font-size: 1rem /* 16/16 */;
                }
            }
        }
    }
    .index-activities{
        .activity-grid{
            padding: 60px 40px;
        }
        .activity-item{
            padding: 20px 0;
            .content{
                .activity-title{
                    a{
                        font-size: 1rem /* 16/16 */;
                    }
                }
            }
        }
    }
    .index-magazine{
        .list-head{
            font-size: 1.125rem /* 18/16 */;
            padding: 13px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .magazine-grid{
            border-width: 3px;
            padding: 60px 30px 40px;
            margin-top: 50px;
        }
        .chapter{
            gap: 13px;
            padding: 13px 0;
            .title{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
    .index-links{
        padding: 60px 0;
    }
}

@media (max-width: $w_tb_m){ 
    .index-head-with-link{
        gap: 10px;
        flex-direction: row;
        justify-content: center;
        .sec-head{
            width: 100%;
        }
    }
    .index-news{
        padding-bottom: 40px;
        .news-grid{
            grid-template-columns: 1fr;
        }
        .news-item{
            padding: 15px;
            &:hover{
                box-shadow: -10px 10px 0 $c_dark;
            }
            .link{
                margin-top: 15px;
            }
        }
    }
    .index-activities{
        .activity-grid{
            padding: 50px 20px;
        }
        .activity-item{
            grid-template-columns: 65px auto;
            gap: 15px;
            .date{
                padding: 10px 5px;
                .day{
                    font-size: 1.75rem /* 28/16 */;
                }
                .month{
                    margin-top: 0;
                }
            }
            .content{
                .activity-title{
                    margin-top: 5px;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }