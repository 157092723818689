/* -------------- header RWD --------------*/
@media (max-width: $w_pc_b){ 
    .header{
        .logo{
            max-width: 380px;
        }
        .main-menu{
            gap: 35px;
            margin-top: 20px;
            > li.has-sub .main-link:after{
                top: 2px;
            }
            .main-link{
                font-size: 1.1875rem /* 19/16 */;
            }
        }
        .sub-menu{
            padding-top: 10px;
            a{
                font-size: 1.0625rem /* 17/16 */;
                padding: 13px 15px;
            }
        }
    }
}

@media (max-width: $w_pc){ 
    .header{
        .logo{
            max-width: 320px;
        }
        .others-link{
            gap: 30px;
            a{
                font-size: 1rem /* 16/16 */;
                &:not(:last-child):after{
                    right: -15px;
                }
            }
        }
        .main-menu{
            gap: 25px;
            > li.has-sub .main-link{
                margin-right: 20px;
                &:after{
                    right: -20px;
                }
            }
            .main-link{
                font-size: 1.125rem /* 18/16 */;
            }
        }
        .sub-menu{
            a{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

@media (max-width: 1100px){ 
    .header{
        padding-top: 25px;
        padding-bottom: 25px;
        .frame{
            align-items: center;
        }
        .others-link{
            margin-right: 50px;
        }
        .main-menu{
            background: rgba(#eee, .95);
            position: fixed;
            padding: 15px 25px;
            top: 0;
            left: 0;
            width: 300px;
            height: 100dvh;
            margin-top: 0;
            overflow-y: auto;
            display: none;
            > li{
                margin: 15px 0;
                padding-bottom: 13px;
                &:not(:last-child){
                    border-bottom: 1px dashed #999;
                }
            }
        }
        .sub-menu{
            position: static;
            transform: translateY(0%);
            width: 100%;
        }
    }
    .mobile-toggle{
        display: block;
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .header{
        padding-top: 15px;
        padding-bottom: 15px;
        .frame{
            display: block;
        }
        .others-link{
            background: #f3f3f3;
            justify-content: center;
            margin-right: 0;
            margin-top: 10px;
            padding: 7px;
        }
    }
    .mobile-toggle {
        top: 27px;
        right: 20px;
    }
}

@media (max-width: $w_tb_m){ 
    .header{
        .logo{
            max-width: 260px;
        }
        .others-link{
            gap: 20px;
            a{
                font-size: .9375rem /* 15/16 */;
                i{
                    margin-right: 7px;
                }
                &:not(:last-child):after{
                    right: -10px;
                }
            }
        }
        .sub-menu a{
            padding: 11px 15px;
        }
    }
    .mobile-toggle {
        top: 21px;
    }
}

// @media (max-width: $w_mo){ }