/* -------------- activity --------------*/
.page-activity{
    .state-btn{
        background: $c_main;
        border-radius: 3px;
        color: $c_white;
        font-size: 1rem /* 16/16 */;
        line-height: 1;
        padding: 15px 10px;
        text-align: center;
        &:hover{
            background: $c_active;
        }
        &.full{
            background: #999 !important;
            cursor: auto;
        }
        &.deadline{
            background: #c5c5c5 !important;
            cursor: auto;
        }
    }
    .activity{
        border: 1px solid #e3e3e3;
        display: grid;
        grid-template-columns: 100px auto;
        &:not(:last-child){
            margin-bottom: 15px;
        }
        &:hover{
            .date{
                background: $c_active;
            }
        }
        .date{
            @include flex($ai:center, $jfc:center);
            background: #888;
            text-align: center;
            p{
                color: $c_white;
                margin: 0;
            }
            .day{
                font-size: 2.75rem /* 44/16 */;
                font-weight: bold;
            }
            .month{
                font-size: .875rem /* 14/16 */;
            }
        }
        .content{
            align-items: center;
            display: grid;
            grid-template-columns: auto 110px;
            gap: 25px;
            padding: 25px;
        }
        .main-info{
            .activity-title{
                color: $c_font;
                display: inline-block;
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                line-height: 1.4;
                margin: 0;
                &:hover{
                    color: $c_active;
                }
            }
            .des{
                @include lineLimit(2);
                color: $c_fontL;
                font-size: .9375rem /* 15/16 */;
                line-height: 1.4;
                margin: 0;
                margin-top: 5px;
                width: 100%;
            }
        }
    }
    .detail-content{
        background: #f6f6f6;
        border: 1px solid #efefef;
        border-radius: 5px;
        padding: 50px 40px;
        .detail-info-list{
            margin-bottom: 15px;
        }
        .info{
            color: $c_font;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            margin-top: 0;
            padding-left: 18px;
            position: relative;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            &:before{
                @include abs_pos($t:7px, $l:2px);
                @include size(6px);
                background: $c_active;
                content: '';
                transform: rotate(45deg);
            }
        }
        .sec-title{
            align-items: center;
            display: flex;
            gap: 10px;
            p{
                background: $c_active;
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                color: $c_white;
                flex-shrink: 0;
                font-size: 1.0625rem /* 17/16 */;
                margin: 0;
                padding: 13px 30px;
            }
            .line{
                border-top: 1px solid #e6e6e6;
                width: 100%;
            }
        }
        .des{
            margin: 25px 0;
        }
    }
    .contact-content{
        margin-top: 50px;
        .group-content{
            border-left: 4px solid $c_main;
            padding-left: 15px;
            &:not(:last-child){
                margin-bottom: 40px;
            }
            p{
                color: $c_font;
                font-size: 1rem /* 16/16 */;
                line-height: 1.4;
                margin: 0;
                &:not(:last-child){
                    margin-bottom: 10px;
                }
                span{
                    display: inline-block;
                }
            }
        }
    }
}