/* -------------- activity RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .page-activity {
        .state-btn{
            width: 120px;
        }
        .activity {
            .info-list{
                padding-top: 10px;
                margin-top: 10px;
            }
            .content{
                gap: 10px;
                grid-template-columns: 1fr;
                padding: 20px;
            }
        }
        .page-detail-head{
            grid-template-columns: 1fr;
            gap: 15px;
        }
        .detail-content{
            padding: 40px 30px;
            .info{
                font-size: 1rem /* 16/16 */;
            }
            .des p{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-activity .activity{
        grid-template-columns: 80px auto;
    }
}

@media (max-width: $w_tb_m){ 
    .page-activity {
        .activity{
            grid-template-columns: 60px auto;
            .date{
                .day{
                    font-size: 2.25rem /* 36/16 */;
                }
                .month{
                    font-size: .8125rem /* 13/16 */;
                }
            }
            .content{
                padding: 15px 13px;
            }
        }
        .detail-content{
            padding: 30px 15px;
            .info{
                span{
                    display: block;
                    font-weight: bold;
                }
            }
            .sec-title p{
                font-weight: bold;
                padding: 10px 20px 10px 15px;
            }
        }
        .contact-content{
            margin-top: 30px;
            .group-content{
                &:not(:last-child){
                    margin-bottom: 30px;
                }
                p .title{
                    display: block;
                    font-weight: bold;
                    width: 100%;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }