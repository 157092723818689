/* -------------- form RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .form-style.third-form{
        gap: 20px;
    }
}

@media (max-width: $w_pc_m){ 
    .login-form{
        grid-template-columns: 1fr;
        gap: 20px;
        .wide{
            grid-column: 1 / 2;
        }
    }
}

@media (max-width: $w_tb_b){ 
    .form-style{
        grid-template-columns: 1fr !important;
        &.third-form{
            .three-halves{
                grid-column: auto;
            }
        }
        .wide{
            grid-column: inherit;
        }
        .remark{
            font-size: .9375rem /* 15/16 */;
            width: 100%;
        }
        .field-label p{
            font-weight: bold;
        }
        .file-input{
            flex-wrap: wrap;
            gap: 0;
        }
    }
}

@media (max-width: $w_tb){ 
    .form-style {
        .field-content{
            flex-wrap: wrap;
            gap: 10px;
        }
        .cheked-list{
            flex-shrink: initial;
            gap: 5px;
        }
        .file-input .file-name{
            width: 200px;
            min-width: 200px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .form-style {
        &.inline-form .field{
            flex-wrap: wrap;
            .field-label{
                padding-top: 0;
            }
        }
        .btn-row button{
            padding: 12px;
            min-width: 110px;
        }
    }
}

// @media (max-width: $w_mo){ }