/* -------------- download --------------*/
.page-download{
    .download{
        align-items: center;
        border: 1px solid #eee;
        display: grid;
        grid-template-columns: auto 110px 30px;
        gap: 10px;
        padding: 13px 25px;
        &:not(:last-child){
            margin-bottom: 10px;
        }
        &:hover{
            background: #f6f6f6;
        }
        .file-name{
            margin: 0;
            a{
                color: $c_font;
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1.4;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .date{
            color: #999999;
            font-size: .875rem /* 14/16 */;
            line-height: 1.2;
            i{
                margin-right: 5px;
            }
        }
        .dl-btn{
            color: $c_main;
            font-size: 1.5rem /* 24/16 */;
            text-align: center;
            width: 30px;
            &:hover{
                color: $c_active;
            }
        }
    }
}