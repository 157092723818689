/* -------------- contact RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-contact{
        .top-des{
            margin-bottom: 30px;
        }
        .map{
            height: 400px;
            margin-bottom: 30px;
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-contact{
        .top-des{
            p{
                font-size: 1rem /* 16/16 */;
            }
        }
        .map{
            height: 300px;
        }
    }
}

// @media (max-width: $w_mo){ }