/* -------------- inquire --------------*/
.page-inquire{
    .search-form{
        @include maw(330px);
        position: relative;
        margin-bottom: 30px;
        input{
            border: 1px solid #ccc;
            border-radius: 3px;
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            padding: 12px 20px;
            padding-right: 35px;
            width: 100%;
        }
        .submit{
            @include abs_pos($t:50%, $r:15px);
            background: none;
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            padding: 0;
            transform: translateY(-50%);
            &:hover{
                color: $c_active;
            }
        }
    }
    .table-limit{
        overflow-x: auto;
        width: 100%;
        table{
            min-width: 900px;
        }
    }
}