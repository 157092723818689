/* -------------- member RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-login{
        gap: 25px;
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-login{
        grid-template-columns: 1fr;
        .form-des{
            margin-bottom: 20px;
        }
    }
}

// @media (max-width: $w_mo){ }