/* -------------- index --------------*/
.index-head-with-link{
    @include flex($ai:flex-end);
    gap: 20px 80px;
}

.index-banner{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    .img-box{
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
    .slick-arrow{
        @include abs_pos($t:50%);
        background: none;
        padding: 0;
        width: 30px;
        z-index: 1;
        &:hover{
            opacity: .5;
        }
        &.slick-prev{
            transform: translateY(-50%) rotateZ(180deg);
            left: 100px;
        }
        &.slick-next{
            transform: translateY(-50%);
            right: 100px;
        }
    }
    .slick-dots{
        @include abs_pos($l:50%, $b:40px);
        @include flex($jfc:center);
        list-style: none;
        margin: 0;
        gap: 10px;
        transform: translateX(-50%);
        padding-left: 0;
        .slick-active{
            button{
                border-color: $c_dark;
                background: $c_dark;
            }
        }
        button{
            @include size(14px);
            background: $c_white;
            border: 1px solid #aaa;
            font-size: 0;
        }
    }
}

.index-intro{
    background: url(../images/index-login-bg.png) no-repeat;
    background-position: top center;
    padding-top: 120px;
    p{
        margin: 0;
    }
    .frame{
        align-items: flex-start;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
        &.is-member{
            display: block;
        }
    }
    section{
        padding-left: 40px;
    }
    .sec-head{
        margin-bottom: 35px;
    }
    .sec-content{
        padding-left: 40px;
    }
    .about-sec{
        .des{
            color: $c_font;
            font-size: 1.25rem /* 20/16 */;
            line-height: 1.6;
            margin-bottom: 100px;
        }
    }
    .login-sec{
        background: url(../images/gf-group.svg) no-repeat;
        background-position: bottom right;
        background-size: 380px;
        padding-bottom: 180px;
        .link{
            color: $c_font;
            display: inline-block;
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            text-decoration: underline;
            margin-top: 10px;
            margin-left: 20px;
            &:hover{
                color: $c_main;
            }
        }
    }
}

.index-news{
    background: url(../images/news-bottom-bg.png) no-repeat;
    background-position: bottom center;
    padding-bottom: 320px;
    margin-top: 40px;
    p{
        margin: 0;
    }
    .news-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-top: 60px;
    }
    .news-item{
        padding: 40px 50px;
        position: relative;
        border: 1px solid transparent;
        box-shadow: 0 0 0 $c_dark;
        transition: box-shadow .4s ease;
        &:hover{
            border-color: $c_main;
            background: url(../images/news-gf-hover.svg) $c_white;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 300px;
            box-shadow: -15px 15px 0 $c_dark;
            position: relative;
        }
        .info{
            @include flex($ai:center);
            gap: 30px;
            .date{
                color: $c_font;
                font-size: .875rem /* 14/16 */;
                font-family: $ff;
                line-height: 1.2;
            }
            .category{
                border-radius: 30px;
                color: $c_white;
                font-size: .875rem /* 14/16 */;
                font-weight: bold;
                padding: 5px 15px;
            }
        }
        .news-title{
            border-bottom: 1px solid $c_grey;
            height: calc(1.125rem * 1.6 * 4);
            overflow: hidden;
            margin-top: 30px;
            a{
                color: $c_font;
                font-size: 1.125rem /* 18/16 */;
                font-weight: bold;
                line-height: 1.6;
                &:hover{
                    color: $c_main;
                }
            }
        }
        .link{
            margin-top: 25px;
            display: inline-block;
            i{
                color: $c_main;
                font-size: 1.125rem /* 18/16 */;
                &:hover{
                    color: $c_active;
                }
            }
        }
    }
}

.index-activities{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    p{
        margin: 0;
    }
    .activity-grid{
        background: url(../images/bg-index-study.jpg) no-repeat;
        background-size: cover;
        padding: 120px 8%;
        transform: translateY(-70px);
        &:nth-child(2){
            background: url(../images/bg-index-general.jpg) no-repeat;
            background-size: cover;
            transform: translateY(0);
            .head .sub{
                color: $c_white;
            }
            .activity-item{
                .date{
                    background: $c_main;
                }
                &:not(:last-child){
                    border-color: $c_white;
                }
            }
        }
    }
    .head{
        text-align: center;
        margin-bottom: 25px;
        .title{
            color: $c_font;
            font-size: 2.5rem /* 40/16 */;
            font-weight: bold;
        }
        .sub{
            color: $c_main;
            font-size: 1.875rem /* 30/16 */;
            font-family: $ff;
            line-height: 1;
            margin-top: 5px;
            text-transform: uppercase;
        }
    }
    .activity-item{
        align-items: flex-start;
        display: grid;
        grid-template-columns: 80px auto;
        gap: 20px 30px;
        padding: 25px 0;
        &:not(:last-child){
            border-bottom: 1px solid $c_dark;
        }
        .date{
            background: $c_dark;
            padding: 15px 5px;
            text-align: center;
            p{
                font-family: $ff;
            }
            .day{
                font-size: 2rem /* 32/16 */;
                font-weight: bold;
                line-height: 1;
            }
            .month{
                font-size: .875rem /* 14/16 */;
                margin-top: 5px;
            }
        }
        .content{
            .state{
                color: $c_active;
                font-size: 1rem /* 16/16 */;
                span{
                    margin: 0 5px;
                }
            }
            .activity-title{
                margin-top: 10px;
                a{
                    color: $c_font;
                    font-size: 1.125rem /* 18/16 */;
                    font-weight: bold;
                    line-height: 1.5;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .btn-row{
        text-align: right;
        margin-top: 20px;
    }
}

.index-magazine{
    background: url(../images/bg-magazine.svg) no-repeat;
    background-position: center bottom;
    position: relative;
    padding-top: 100px;
    &:after{
        @include abs_pos($l:2%, $b:21%);
        content: url(../images/gf-mag.svg);
        width: 28%;;
    }
    p{
        margin: 0;
    }
    .magazine-grid{
        @include maw(1120px);
        border: 5px solid $c_main;
        background: $c_white;
        margin-left: auto;
        margin-top: 110px;
        padding: 50px 80px;
        position: relative;
    }
    .list-head{
        @include abs_pos($t:0, $l:80px);
        border-radius: 30px;
        background: $c_font;
        color: $c_white;
        display: inline-block;
        font-size: 1.25rem /* 20/16 */;
        font-weight: bold;
        text-align: center;
        transform: translateY(-50%);
        padding: 13px 15px;
        min-width: 210px;
    }
    .chapter{
        align-items: baseline;
        border-bottom: 1px solid $c_grey;
        display: grid;
        grid-template-columns: 30px auto 30px;
        gap: 15px;
        padding: 25px 0;
        position: relative;
            &:after{
                @include abs_pos($l:0, $b:0);
                @include size(0, 5px);
                background: $c_active;
                content: '';
                transition: all .4s ease;
            }
        &:hover{
            &:after{
                width: 100%;
            }
        }
        .no{
            color: $c_main;
            font-size: 1.25rem /* 20/16 */;
            font-weight: bold;
            font-family: $ff;
        }
        .title{
            color: $c_font;
            font-size: 1.125rem /* 18/16 */;
            line-height: 1.4;
        }
        .link{
            color: $c_main;
            font-size: 1.5rem /* 24/16 */;
            &:hover{
                color: $c_active;
            }
        }
    }
}

.index-links{
    background: $c_main;
    padding: 110px 0;
    .slick-dots{
        @include flex($jfc:center);
        list-style: none;
        margin: 0;
        padding-left: 0;
        gap: 8px;
        button{
            @include size(10px);
            background: $c_white;
            border: 1px solid $c_grey;
            font-size: 0;
        }
        .slick-active{
            button{
                background: $c_light;
                border-color: $c_light;
            }
        }
    }
}