/* -------------- layout RWD --------------*/
@media (max-width: $w_pc_b){ 
    aside{
        width: 240px;
        .aside-head{
            margin-bottom: 15px;
            .title{
                font-size: 1.625rem /* 26/16 */;
            }
            .sub{
                font-size: .9375rem /* 15/16 */;
            }
        }
        .aside-menu{
            >li >a{
                font-size: 1.0625rem /* 17/16 */;
                padding: 13px 20px;
            }
        }
    }
    .page-frame-main{
        padding-left: 40px;
        width: calc(100% - 240px);
    }
}

@media (max-width: $w_pc){ 
    .breadcrumb{
        padding-top: 50px;
    }
    .table-grid{
        td{
            padding: 13px;
        }
    }
}

@media (max-width: 1200px){ 
    .sec-head{
        padding-left: 20px;
        &:before{
            top: -10px;
            width: 50px;
        }
        .title{
            font-size: 2rem /* 32/16 */;
        }
        .sub{
            font-size: 1.5rem /* 24/16 */;
        }
    }
    aside{
        width: 100%;
        .toggle-page-menu{
            display: block;
        }
        .aside-menu{
            display: none;
        }
        .aside-dec{
            display: none;
        }
    }
    .page-frame-main{
        margin-top: 30px;
        padding-left: 0;
        width: 100%;
    }
    .page-frame-main .main-head .title{
        font-size: 1.5rem /* 24/16 */;
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .sec-head{
        gap: 5px;
        .title{
            font-size: 1.75rem /* 28/16 */;
        }
        .sub{
            font-size: 1.125rem /* 18/16 */;
        }
    }
    .rwd-table{
        thead{
            display: none;
        }
        tr{
            border-bottom: 1px solid $c_grey;
            display: block;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        td{
            border: none;
            display: block;
            padding: 5px 0 5px 100px;
            position: relative;
            margin-bottom: 5px;
            &:before{
                @include abs_pos($t:0, $l:0);
                content: attr(data-tableCell);
                background: #ffe9d2;
                display: inline-block;
                padding: 5px;
                text-align: center;
                width: 80px;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .breadcrumb{
        justify-content: flex-start;
        padding-top: 20px;
        a{
            font-size: .875rem /* 14/16 */;
        }
    }
    .page-frame-main .main-head{
        margin-bottom: 30px;
    }
    aside{
        .aside-menu{
            >li > a{
                padding: 7px 13px;
            }
        }
    }
    .page-frame{
        padding-top: 10px;
        padding-bottom: 50px;
    }
    .page-detail-head{
        margin-bottom: 20px;
    }
}

// @media (max-width: $w_mo){ }