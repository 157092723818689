/* -------------- footer RWD --------------*/
@media (max-width: $w_pc_b){ 
    .footer{
        .ft-logo{
            max-width: 380px;
        }
        .contact-sec{
            gap: 40px;
            margin-left: 50px;
            padding-left: 50px;
            width: calc(100% - 380px - 50px);
        }
    }
}

@media (max-width: $w_pc){ 
    .footer{
        .main-frame{
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .ft-logo{
            max-width: 320px;
        }
        .contact-sec{
            margin-left: 40px;
            padding-left: 40px;
            width: calc(100% - 320px - 40px);
        }
    }
    .top-btn{
        right: 0;
        span{
            font-size: .875rem /* 14/16 */;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .footer{
        .main-frame{
            display: block;
        }
        .contact-sec{
            border-left: none;
            grid-template-columns: 1fr;
            gap: 10px;
            margin-left: 0;
            margin-top: 25px;
            padding-left: 0;
            width: 100%;
        }
        .bottom-frame{
            padding: 20px 0;
        }
    }
}

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .footer{
        .main-frame{
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .ft-logo{
            max-width: 260px;
        }
        .info-list, .post-info{
            display: block;
            .info{
                &:not(:last-child){
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }
        }
        .contact-sec{
            gap: 20px;
        }
        .bottom-frame{
            padding: 15px 0;
            .frame{
                display: block;
            }
            p, a, b{
                font-size: .875rem /* 14/16 */;
            }
            .designed{
                margin-top: 5px;
            }
        }
    }
    .top-btn{
        padding: 5px;
    }
}

// @media (max-width: $w_mo){ }