/* -------------- footer --------------*/
.footer{
    background: url(../images/repeat-grey.jpg);
    font-family: $ff;
    p{
        margin: 0;
    }
    .main-frame{
        @include flex;
        align-items: flex-start;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .ft-logo{
        @include maw(450px);
        display: block;
    }
    .contact-sec{
        align-items: flex-start;
        display: grid;
        grid-template-columns: auto 240px;
        gap: 60px;
        border-left: 1px solid #ccc;
        margin-left: 70px;
        padding-left: 70px;
        width: calc(100% - 450px - 70px);
    }
    .info-list{
        @include flex;
        .info{
            display: grid;
            grid-template-columns: 25px auto;
            i{
                color: $c_main;
                font-size: 1rem /* 16/16 */;
            }
            p{
                color: $c_font;
                font-size: 1rem /* 16/16 */;
                line-height: 1.2;
                .title{
                    display: inline-block;
                }
            }
            a{
                color: $c_font;
                text-decoration: underline;
                &:hover{
                    color: $c_active;
                }
            }
            &:not(:last-child){
                margin-right: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .post-info{
        @include flex;
        margin-top: 20px;
        .info{
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            padding-left: 13px;
            position: relative;
            line-height: 1.2;
            &:before{
                @include abs_pos($t:5%, $l:0);
                @include size(4px, 87%);
                background: $c_active;
                content: '';
            }
            &:not(:last-child){
                margin-right: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .right-sec{
        align-items: center;
        display: grid;
        grid-template-columns: 45px auto;
        gap: 15px;
        .icon{
            display: block;
            width: 100%;
        }
        p{
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
        }
        &:hover p{
            color: $c_active;
        }
    }

    .bottom-frame{
        background: $c_font;
        padding: 30px 0;
        .frame{
            @include flex($jfc:space-between);
        }
        p, a, b{
            color: $c_white;
            font-size: .9375rem /* 15/16 */;
            line-height: 1.2;
        }
    }
}

.top-btn{
    position: fixed;
    right: 20px;
    bottom: 30px;
    background: $c_active;
    display: block;
    padding: 10px;
    text-align: center;
    opacity: 0;
    &:hover{
        background: $c_main;
    }
    i{
        color: $c_white;
    }
    span{
        color: $c_white;
        display: block;
        font-size: 1rem /* 16/16 */;
        text-align: center;
    }
}