/* -------------- temp RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-news{
        .news{
            padding: 25px 30px;
        }
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-news{
        .news{
            padding: 25px 20px;
        }
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }