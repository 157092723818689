/* -------------- magazine --------------*/
.page-magazine{
    .magazine-past-table{
        a{
            display: inline-block;
        }
        .no-link{
            color: $c_link;
            &:hover{
                color: $c_active;
                text-decoration: underline;
            }
        }
        .to-exam{
            color: $c_fontL;
            &.is-link:hover{
                color: $c_active;
            }
            i{
                margin-right: 5px;
            }
        }
    }
    .magazine-head{
        @include flex($ai:flex-start, $jfc:space-between);
        margin-top: -39px;
        margin-bottom: 20px;
        p{
            margin: 0;
        }
        .current-info{
            @include flex($ai:center);
        }
        .info{
            display: flex;
            background: #40b8ca;
            color: $c_white;
            font-size: 1.25rem /* 20/16 */;
            font-weight: lighter;
            padding: 15px;
            padding-left: 20px;
            position: relative;
            height: 57px;
            text-decoration: none;
            &:before{
                @include abs_pos($t:0, $r:0);
                content: '';
                border-style: solid;
                border-width: 57px 25px 0 0;
                border-color: #40b8ca transparent transparent transparent;
                transform: translateX(100%);
            }
            p{
                &:not(:last-child):after{
                    content: '/';
                    color: $c_white;
                    font-size: 1.125rem /* 18/16 */;
                    padding: 0 5px;
                }
                a{

                }
            }
        }
        .exam-link{
            background: #0f85bb;
            color: $c_white;
            display: inline-block;
            font-size: 1.0625rem /* 17/16 */;
            padding: 17px 20px 17px 15px;
            position: relative;
            margin-left: 27px;
            height: 57px;
            &:hover{
                background: $c_active;
                &:before{
                    border-color: transparent transparent $c_active transparent;
                }
            }
            &:before{
                @include abs_pos($t:0, $l:0);
                content: '';
                border-style: solid;
                border-width: 0 0 57px 25px;
                border-color: transparent transparent #0f85bb transparent;
                transform: translateX(-100%);
                transition: all .4s ease;
            }
            i{
                margin-right: 5px;
            }
        }
        .filter-row{
            @include flex;
            gap: 5px;
            margin-top: 25px;
        }
    }
    .magazine-des{
        .remark{
            color: $c_active;
            font-size: 1.0625rem /* 17/16 */;
            font-weight: bold;
            line-height: 1.4;
            margin: 0;
            span{
                display: inline-block;
            }
        }
    }
    .magazine-list{
        padding-top: 10px;
    }
    .magazine{
        padding:  17px 0;
        &:not(:last-child){
            border-bottom: 1px solid $c_grey;
        }
        .magazine-title{
            color: $c_font;
            display: inline-block;
            font-size: 1.0625rem /* 17/16 */;
            font-weight: bold;
            line-height: 1.4;
            margin-bottom: 5px;
            &:hover{
                color: $c_active;
            }
        }
    }
    .sub-des{
        margin-bottom: 25px;
        p{
            color: $c_font;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            margin: 0;
        }
        a{
            color: $c_link;
            display: inline-block;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            &:hover{
                color: $c_active;
            }
        }
    }
    .exam-top-head{
        margin: 20px 0;
        p{
            margin: 0;
        }
        .main{
            color: $c_font;
            font-size: 1.125rem /* 18/16 */;
            line-height: 1.4;
        }
        .sub{
            color: $c_fontL;
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
            margin-top: 5px;
        }
    }
    .exam-file{
        align-items: baseline;
        display: flex;
        gap: 20px;
        .download-link{
            background: linear-gradient( 0deg, rgb(215,56,1) 0%, rgb(247,86,30) 100%);;
            border-radius: 3px;
            color: $c_white;
            display: inline-block;
            font-size: 1.125rem /* 18/16 */;
            flex-shrink: 0;
            line-height: 1;
            padding: 18px 25px;
            i{
                margin-right: 8px;
            }
        }
        .link-remark{
            color: $c_active;
            font-size: 1rem /* 16/16 */;
            line-height: 1.4;
        }
    }
    .exam-grid{
        background: #f6f6f6;
        border: 1px solid #efefef;
        border-radius: 5px;
        padding: 50px 40px;
        margin-top: 25px;
        .exam-head{
            margin-bottom: 30px;
            padding-left: 25px;
            position: relative;
            &:before{
                @include abs_pos($t:1px, $l:0);
                content: '\f0c9';
                color: $c_active;
                font-size: 1.125rem /* 18/16 */;
                font-family: 'Font Awesome 6 Free';
                font-weight: bold;
            }
            .exam-text{
                color: $c_font;
                font-size: 1.125rem /* 18/16 */;
                font-weight: bold;
                line-height: 1.2;
                margin: 0;
            }
        }
        .exam-list{
            padding-left: 20px;
            margin: 0;
        }
        .exam{
            &:not(:last-child){
                margin-bottom: 30px;
            }
            .exam-q{
                color: $c_font;
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 15px;
            }
            .exam-content{
                display: flex;
                flex-direction: column;
                gap: 5px;
                input{
                    margin: 0;
                    margin-right: 8px;
                }
                .input-text{
                    color: $c_font;
                    display: inline-block;
                    font-size: 1rem /* 16/16 */;
                    line-height: 1.6;
                }
            }
        }
        .radio-item{
            align-items: baseline;
            display: inline-flex;
            input{
                flex-shrink: 0;
            }
        }
        .btn-row{
            display: flex;
            justify-content: center;
            margin-top: 30px;
            button{
                background: $c_main;
                border-radius: 3px;
                color: $c_white;
                font-size: 1.0625rem /* 17/16 */;
                padding: 15px;
                text-align: center;
                min-width: 130px;
                &:hover{
                    background: $c_active;
                }
            }
        }
    }
}