/* -------------- links RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-links{
        gap: 0;
    }
}

@media (max-width: $w_pc_m){ 
    .page-links{
        grid-template-columns: repeat(3, 1fr);
    }
}

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-links{
        grid-template-columns: repeat(2, 1fr);
    }
}

// @media (max-width: $w_mo){ }