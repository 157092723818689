/* -------------- news --------------*/
.page-news{
    .news{
        border: 1px solid #e3e3e3;
        padding: 30px 40px;
        &:hover{
            background: rgba($c_light, .2);
        }
        &:not(:last-child){
            margin-bottom: 20px;
        }
        p{
            margin: 0;
        }
        .category{
            align-items: center;
            display: flex;
            font-weight: bold;
            font-size: 1rem /* 16/16 */;
            gap: 10px;
            margin-bottom: 10px;
            .square{
                @include size(13px);
            }
        }
        .news-title{
            color: $c_font;
            font-size: 1.0625rem /* 17/16 */;
            font-weight: bold;
            line-height: 1.4;
            &:hover{
                color: $c_active
            }
        }
        .des{
            @include lineLimit(2);
            color: $c_fontL;
            font-size: .9375rem /* 15/16 */;
            line-height: 1.6;
            margin-top: 8px;
        }
    }
}