/* -------------- contact --------------*/
.page-contact-frame{
    background: linear-gradient(0deg, #f0f0f0 70%, #fff 70%);
}
.page-contact{
    .top-des{
        margin-bottom: 50px;
        span{
            display: inline-block;
        }
        p{
            color: $c_font;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            margin: 0;
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
        a{
            color: $c_link;
            display: inline-block;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .map{
        border: 3px solid $c_white;
        margin-bottom: 50px;
        height: 480px;
        position: relative;
        &:after{
            @include abs_pos($l:50%, $b:-3px);
            background: url(../images/sh-map.png) no-repeat;
            background-size: contain;
            @include size(1265px, 3px);
            background-position: top center;
            content: '';
            transform: translate(-50%, 100%);
            max-width: 100%;
        }
        iframe{
            @include size(100%);
        }
    }
}